<template>
  <b-card>
    <!-- Описание -->
    <b-form-group
        label="Описание"
        label-for="description"
    >
      <quill-editor
          id="description"
          v-model="about"
          :options="editorOption"
      />
    </b-form-group>
    <b-button
        variant="primary"
        class="mt-2 float-right"
        type="button"
        @click="save"
    >
      Сохранить
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton, BFormGroup, } from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    quillEditor,
    ImageResize,
  },
  data() {
    return {
      about: '',
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {
          },
          toolbar: {
            container:  [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
    }
  },
  methods: {
    getAbout() {
      this.$http
        .post(`https://edu.startupchoikhona.tj/backend/api/about/index`, {})
        .then(response => {
          const {
            data,
          } = response.data
          this.about = data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение данный',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    save() {
      this.$http
        .post(`https://edu.startupchoikhona.tj/backend/api/about/create-or-update`, {
          text: this.about,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно сохранено.',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при сохранение данный',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  created() {
    this.getAbout()
  },
}
</script>

<style>

</style>
